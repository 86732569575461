.wishlist-page {
  .wishlist-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
  }

  .wishlistItemCards {
    margin: 0 2.35rem;

    > .row {
      gap: 2.5rem;
      list-style: none;
      padding: 0;
    }
  }

  // MARG-629
  .product,
  .product-brand,
  .product-tile {
    height: 100%;

    // IBT-3658
    width: 100%; // https://stackoverflow.com/a/49351775
  }

  .mm-product-card {
    // MARG-627 MARG-663
    // &:not(.-brand-mm6) {
    .mm-product-card__info__default {
      align-items: center;
    }
    // }

    &.-brand-mm {
      @for $var from 1 through 10 {
        .mm-product-card__hero-#{$var} {
          @include mask('2x3', $var);
        }
      }
    }
  }

  .product-tile {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    position: relative;

    .prior-best-price-component {
      position: relative;

      .prior-best-price-info-btn {
        border: none;
        background: none;
        padding: 0;
        display: flex;
      }

      .prior-best-price-tooltip {
        position: absolute;
        bottom: rem(15);
        left: rem(5);
        width: 190px;
        text-align: center;
        font-size: rem(10);
        padding: rem(8);
        font-weight: 500;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);

        .price {
          display: inline;
          font-size: rem(10);
        }
      }
    }

    .discount-disclaimer {
      display: none;
    }
  }

  // MARG-629
  .product-top {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .tile-image {
    // height: -webkit-fill-available;
    aspect-ratio: 2 / 3;
    object-fit: cover;
    width: -webkit-fill-available;
  }

  .mm-product-card__hero__hover {
    display: none;
  }

  .mm-product-card__info {
    // align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1rem 0.5rem 0;
  }

  .mm-product-card__info__default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
  }

  .single-element-content-detail-description {
    font-size: 16px;
    // justify-self: center;
    line-height: 160%;
    min-width: 0; // MARG-627
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .single-element-content-detail-price {
    margin-top: 8px;
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    // justify-self: right;
    line-height: 17px;
    margin-bottom: rem(4);

    &.sales.discount-percentage {
      color: #6b6b6b;
    }

    .discount-percentage {
      color: #6b6b6b;
    }
  }

  .badge-container,
  .search-result-mm6-badge-wrapper {
    margin-top: 2px;
  }
  .single-element-content-detail-badge {
    display: flex;
    align-items: center;
    order: 3;
    > div {
      &:not(:last-child) {
        > div,
        > span {
          margin-right: rem(4);
        }
      }
    }
  }

  .price {
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
    // MARG-684
    flex-wrap: wrap;
    justify-content: center;
  }

  // MARG-663
  .default-price-container {
    display: flex;
    column-gap: 0.5rem;

    // MARG-684
    .value {
      white-space: nowrap;
    }
  }

  .badge-container {
    column-gap: 0.5rem;
    display: flex;
  }

  // MARG-684
  .product-badge {
    white-space: nowrap;
  }

  .search-result-mm6-badge {
    display: flex;
    margin-bottom: 0;
  }

  .add-to-wish-list {
    height: 2rem;
    justify-self: left;
    padding: 0.35rem 0 0;
    // pointer-events: none;
  }

  .add-to-wish-list.active {
    .wishlist-icon {
      display: none;
    }
  }

  // MARG-629
  .mm-product-card__info__hover {
    align-self: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: end;
  }

  .colors-title {
    display: none;
  }

  .colors-box {
    padding: 0;
  }

  .remove-from-wishlist,
  .remove-from-wishlist .mm-button.-icon {
    color: $ecru-95;
    font-size: 13px;
    font-weight: 500;
    height: auto;
    line-height: 16px;
    position: absolute;
    right: 6%;
    top: 3%;
    z-index: 2;
    padding: 10px;
    border-radius: 99px;
  }

  .add-to-cart-add-to-wishlist {
    margin-bottom: 0;

    .quantity-switch,
    .size-switch {
      // MARG-672
      padding: 0;
    }

    .size-guidelines-trigger-box {
      color: $ecru-50;
      justify-content: center;

      button {
        color: $ecru-50;

        .size-guidelines {
          color: $ecru-50;
        }
      }
    }

    .product-size-select {
      // MARG-672
      width: 100%;
      max-width: calc(100% - 1rem);
    }

    .select-size {
      background-position: right 0.25rem center;
      background-size: 0.55rem 0.55rem;
      font-size: 15px;
      line-height: 18px;
      padding-left: 0.25rem;
      padding-right: 1.5rem;
      // MARG-685
      text-align: center;
    }
  }

  .custom-switch {
    text-align: center;
  }

  .quantity-switch,
  .size-switch {
    background-color: $ecru-10;
    margin-bottom: 10px;
    width: fit-content;
    min-width: 140px;
    max-width: 100%;
  }

  .add-to-cart-switch,
  .add-to-cart-switch.-active {
    background-color: $black;
    width: fit-content;

    .add-to-cart {
      color: $white;
    }
  }
  .quantity-switch.-active,
  .size-switch.-active {
    background-color: $ecru-10;
    box-shadow: none;
  }

  .title-wishlist-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    .mm-product-card__title {
      margin-right: 0;
      font-size: 16px;
      font-weight: 400;
    }

    .js_add-to-wish-list {
      display: none;
    }
  }

  .single-element-content-image {
    display: block;
    height: 100%;

    .image-container,
    .mouseover-container {
      height: auto;
    }
  }
  .wishlistItemCards {
    $timing-function: cubic-bezier(0.16, 1, 0.3, 1);

    .mm-product-card {
      position: relative;

      &__hero {
        transition: transform 0.6s $timing-function;
        transform: translateY(0);
        cursor: pointer;
        display: block;
        margin-bottom: 0.5rem;
        backface-visibility: hidden;

        &__aspect {
          @include ratio-2x3();
          position: relative;
          overflow: hidden;
          padding-top: map-get($aspect-ratios, '2x3');
        }

        &__default,
        &__hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          user-select: none !important;
          -webkit-touch-callout: none !important;
          -webkit-user-select: none !important;
          img {
            height: inherit;
            width: inherit;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-50%);
          }
        }

        &__default {
          opacity: 1;
          display: block;
        }

        &__hover {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }

        .mm-videoloop {
          height: 100%;

          .vjs-has-started .vjs-loading-spinner {
            display: none;
          }

          &.amp-default-skin .vjs-control-bar .amp-controlbaricons-left {
            text-align: center;
          }
        }
      }
    }
  }
}

#wishlist-show {
  .wishlistItemCards {
    margin-top: 4.25rem;
  }
}

#wishlist-accountshow {
  .help-title {
    font-size: 26px;
  }

  .wishlistItemCards {
    margin-top: 2rem;
  }
}

.js-button-wishlist.-dot-info::after {
  background: $ecru-95 !important;
}

.cart-head {
  .-badge-end {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
}

// MARG-630
.page-search-result-container {
  .mm-product-card {
    // .add-to-wish-list {
    //   opacity: 0;
    // }

    &.-inview {
      .add-to-wish-list {
        opacity: 1;
        transform: translateY(0);
        transition-duration: 2500ms;
      }
    }
  }
}
