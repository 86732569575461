.cart-page {
  padding: 0 1rem 1.5rem;
  min-height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;

  &.empty-cart-page {
    min-height: calc(100vh - 14rem);
  }
  .buttons-wrapper {
    display: grid;
    column-gap: rem(10);
    grid-template-columns: 1fr 1fr;
  }

  .mm-switch__toggle {
    @include type('global-control-productive-3');

    a {
      display: flex;
      align-items: center;
    }
  }

  .detail-attribute {
    &.color {
      order: 2;
    }
  }

  .product-attributes.product-info-details {
    .quantity-label {
      order: 3;
      @include type('global-control-productive-3');
      font-size: rem(14);
      line-height: rem(17);
    }
  }

  .js-lineitem {
    .cart-item-grid {
      .cart-edit-link {
        text-transform: uppercase;
        text-decoration: underline;
        border-left: 0;
        padding-left: 0;
        font-size: 14px;
      }

      .product-edit {
        .js-remove-from-cart {
          background: none;
          border: none;
          padding: 0;
          opacity: 0.7;

          .mm-icon {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
  }

  #cart-paypal-button-container {
    position: relative;
    z-index: 1;
  }

  .modal-edit-product {
    .modal-footer {
      padding: 20px;
      // position: sticky;
      // bottom: 0;
      // background: #f8f7f2;
    }

    .mm-switch {
      display: block;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: unset;
      padding: 0.25rem 0.125rem;
      color: $ecru-95;
      background-color: unset;
      border-radius: $border-radius-8;
    }
    .quantity-switch.mm-switch__toggle,
    .size-switch.mm-switch__toggle {
      margin-bottom: 24px;
      padding: 0;
      background-color: $white;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
      > .mm-form-select {
        width: 100%;
        select {
          text-align: center;
        }
      }
    }

    .size-switch.mm-switch__toggle.no-margin {
      margin-bottom: 0;
    }

    .add-to-cart-switch {
      background-color: $black;

      .add-to-cart {
        color: $white;
      }
    }
  }

  .cart-lineitem-recommendation {
    font-size: 12px;
    font-weight: 500;
    line-height: 15.73px;
    text-align: left;
    height: 2rem;
    padding: 0 0.8125rem;

    .look-icon {
      margin-left: 10px;
    }
  }

  .row-cart-content {
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: 1220px;
    margin: 0 auto;
    width: 100%;
  }

  .cart-content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .empty-cart-content {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      text-align: center;
    }
  }

  .preorder-wrapper {
    &.product-message-eta {
      border-top: 1px solid get-color('ecru-95', 10);
      padding: 0 0.5rem !important;
    }
  }
  .preorder-information-wrapper {
    padding-right: 0.5rem;
    flex: 1;
    .eta-extra-info-title {
      // title in blu
      color: $info-50;
      padding: 0 1rem;
    }
    .eta-info-icon {
      height: 1rem;
      position: relative;
      width: 1rem;
      float: right;
      top: -1rem;
    }
    .eta-extra-info-icon {
      position: absolute;
      top: 0;
      right: 0;
      color: $info-30-24;

      &.active {
        color: $info-50;
      }
    }
    .eta-extra-info-message {
      // content
      padding: 0 1rem;
      display: none;
      &.visible {
        display: block;
        padding-bottom: 0.75rem;
      }
    }
  }

  .dw-apple-pay-button {
    &.apple-pay-cart-button,
    &.apple-pay-button {
      &,
      &:hover {
        -webkit-appearance: -apple-pay-button;
        margin: 0;
        height: 48px;
        width: 100%;
        display: block;
        -apple-pay-button-type: plain;
        -apple-pay-button-style: black;
      }
    }
  }

  .line-item-total-price {
    .price {
      &.cart {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .prior-best-price-component {
          position: relative;
          margin-right: 8px;

          .prior-best-price-info-btn {
            border: none;
            background: none;
            padding: 0;
            display: flex;
          }

          .prior-best-price-tooltip {
            position: absolute;
            bottom: 0.625rem;
            left: -11.5rem;
            width: 190px;
            text-align: center;
            font-size: rem(10);
            padding: rem(8);
            font-weight: 500;
            background-color: #fff;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);

            .price {
              display: inline;
              font-size: rem(10);
            }
          }
        }

        .prior-best-price-discount {
          flex: 0 0 auto;
          color: #6b6b6b;
        }

        .strike-through {
          flex: 0 0 auto;
          color: #838383;
          margin-left: 8px;
          margin-right: 8px;
        }

        &:not(.no-promo) {
          .prior-best-price-component {
            margin-right: 0;
            flex: 0 0 100%;
            justify-content: flex-end;
            display: flex;
            margin-bottom: 0.5rem;

            .prior-best-price-tooltip {
              left: 0;
            }
          }

          .pricing {
            margin-top: 8px;
            flex: 0 0 100%;
            text-align: right;
          }
        }
      }
    }
  }
}
